
import {Component, Ref, Vue} from "vue-property-decorator";
import {
  AttachmentDto,
  AttachmentHostType,
  AuditFlowScope, BeneficiaryDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
// import ExportWord from "@/components/DownloadWord/index.vue";
import ExportWord from "@/components/DownloadZipFile/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import AuditButtons from "@/components/AuditButtons/index.vue";
import {ZipFileInput} from "@/utils/htmlToZipFile/commonDto";

@Component({
  name: "beneficiaryDetail",
  components: {
    ExportWord,
    AttachmentsView,
    AuditButtons
  },
})
export default class BeneficiaryDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  dataId?: number;
  detail: BeneficiaryDto = {};
  styles: string = "";
  selector: string = "table-detail-world";
  filename: string = `受益人[${this.detail.realName}]基本信息表`;
  attachmentList: AttachmentDto[] = [];
  hostType = AttachmentHostType.Beneficiary;
  otherFiles: ZipFileInput[] = [];
  // auditParams: AuditBodyDto = {
  //   hostId: undefined,
  //   hostType: AuditFlowScope.PartnerCapitalApply,
  //   auditFlowId: undefined,
  //   auditStatus: undefined,
  //   audit: undefined
  // }

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail();
      this.getAttachments();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.beneficiary.get({id: this.dataId}).then(res => {
      this.detail = {...res};

      // this.auditParams!.hostId = res.id +''
      // this.auditParams!.auditFlowId = res.auditFlowId;
      // this.auditParams!.auditStatus = res.auditStatus;
      // this.auditParams!.audit = res.audit;
      // if(res.auditFlowId) {
      //   (this.auditButtons as any).fetchCanAudit()
      // }
    });
  }

  // 获取附件
  async getAttachments() {
    await api.attachmentService.getAttachments({
      hostId: this.dataId!.toString(),
      hostType: AttachmentHostType.Beneficiary
    }).then(res => {
      this.attachmentList = res;
      if (this.attachmentList && this.attachmentList.length > 0) {
        this.attachmentList.forEach(s => {
          this.otherFiles.push({
            folder: '其他资料',
            files: [
              {
                title: s.fileName!,
                path: s.url!
              }
            ]
          })
        })
      }
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }

  fetchData() {
    return api.beneficiary.audit;
  }

  changeAudit() {
    this.fetchDetail()
  }
}
