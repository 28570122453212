

import {Component, Ref, Vue} from "vue-property-decorator";
import {
  AttachmentHostType,
  BeneficiaryCreateOrUpdateDto,
  DataDictionaryDto,
  FamilyMembersInfoDto
} from "@/api/appService";
import api from "@/api";
import {ElForm} from "element-ui/types/form";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import ProvinceCityArea from "@/components/ProvinceCityArea/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ProvinceCityAreaControl from "@/components/ProvinceCityAreaControl/index.vue";


interface ProvinceCityAreaTownDto {
  /**  */
  provinceId?: undefined;

  /**  */
  cityId?: undefined;

  /**  */
  areaId?: undefined;

  townId?: undefined;
}

@Component({
  name: "editInternalProjectBeneficiary",
  components: {
    ProvinceCityAreaControl,
    MultipleUploadFile,
    ProvinceCityArea,
    AbSelect
  },
})
export default class EditInternalProjectBeneficiary extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;
  formId?: number;
  areaSelect: ProvinceCityAreaTownDto = {
    provinceId: undefined,
    cityId: undefined,
    areaId: undefined,
    townId: undefined
  }
  sexList: DataDictionaryDto[] = []
  nationList: DataDictionaryDto[] = []
  familyMembersInfoList: FamilyMembersInfoDto = {
    name: undefined,
    appellation: undefined,
    birthDate: undefined,
    health: undefined,
    occupation: undefined,
    annualIncome: undefined,
  }
  formData: BeneficiaryCreateOrUpdateDto = {
    code: undefined,
    realName: undefined,
    birthday: undefined,
    areaSelect: this.areaSelect,
    savedAttachments: [],
    familyMembersInfoList: [],
    isEnjoyOtherSalvation: false,
    isEnjoyTheSubsistenceAllowancePolicy: false
  };
  form: any = {
    ...this.formData
  };

  get hostTypeFile() {
    return AttachmentHostType.Beneficiary;
  }

  created() {
    this.form.savedAttachments = []
    this.fetchDataDictionary();
    this.fetchNationDataDictionary();
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail()
    }
  }

  fetchDataDictionary() {
    api.dataDictionary.getDataDictionaryListByKey({key: 'Sex', maxResultCount: 1000})
      .then((res: any) => {
        this.sexList = res.items
      })
  }

  fetchNationDataDictionary() {
    api.dataDictionary.getDataDictionaryListByKey({key: 'Nation', maxResultCount: 1000})
      .then((res: any) => {
        this.nationList = res.items
      })
  }

  // 获取详情
  async fetchDetail() {
    await api.beneficiary.get({id: this.dataId}).then(res => {
      this.form = {...res}
      this.form.familyMembersInfoList = this.form.familyMembersInfoList ?? [];
      if (this.form.sex) {
        this.form.sex = Number(this.form.sex)
      }
      if (this.form.nation) {
        this.form.nation = Number(this.form.nation)
      }
      if (!res.areaSelect) {
        this.form.areaSelect = this.areaSelect
      }

      if (!this.form.savedAttachments) {
        this.form.savedAttachments = []
      }
    })
  }

  cancel() {
    this.$router.go(-1)
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.beneficiary.update({
            body: {...this.form}
          }).then(() => {
            this.$message.success("更新成功");
            this.$router.go(-1)
          });
        } else {
          await api.beneficiary.create({
            body: {...this.form}
          }).then(() => {
            this.$message.success("新建成功");
            this.$router.go(-1)
          });
        }
      } else {
        this.$message({
          type: 'error',
          message: '请检查表单'
        })
      }
    });
  }

  // 增加家庭成员情况
  private addRow(r: number) {
    const obj = {
      name: undefined,
      appellation: undefined,
      birthDate: undefined,
      health: undefined,
      occupation: undefined,
      annualIncome: undefined,
    };

    this.form.familyMembersInfoList = [...this.form.familyMembersInfoList!, obj];
  }

  // 删除家庭成员情况
  private deleteRow(index: number) {
    this.form.familyMembersInfoList!.splice(index, 1);
  }

  roleRule = {
    realName: [
      {
        required: true,
        message: "姓名必填",
        trigger: "blur"
      }
    ],
    principalName: [
      {
        required: true,
        message: "负责人必填",
        trigger: "blur"
      }
    ],
    principalPhone: [
      {
        required: true,
        message: "负责人电话必填",
        trigger: "blur"
      }
    ],
    sex: [
      {
        required: true,
        message: "性别必填",
        trigger: "blur"
      }
    ],
    idNo: [
      {
        required: true,
        message: "身份证号必填",
        trigger: "blur"
      }
    ],
    phone: [
      {
        required: true,
        message: "联系电话必填",
        trigger: "blur"
      }
    ]
    , homeAddress: [
      {
        required: true,
        message: "详细地址必填",
        trigger: "blur"
      }
    ]
    , nation: [
      {
        required: true,
        message: "民族必填",
        trigger: "blur"
      }
    ]
    , birthday: [
      {
        required: true,
        message: "出生年月必填",
        trigger: "blur"
      }
    ]
  }
}
